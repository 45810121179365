import React, { Component, } from 'react';
import { Row, Col, Layout, Card, List, Typography, Tag, Space } from 'antd'
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSearch from '../../../Hooks/useSearch';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { ButtonView } from '../../Widgets/Buttons';

import '../../../Styles/Modules/Customer/Dashboard.scss'

const { Content } = Layout;
const { Text } = Typography;

/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Vista donde se muestra un resumen de las estadisticas del sistema
 */
class Dashboard extends Component {

    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }


    componentDidMount() {
        this.props.setShowSearch(false)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
    }

    componentWillUnmount(){
        this.props.setShowSearch(true)
    }


    renderTransaccion = (item) => {
        return (
            <List.Item className="dashboard-transactions-item">
                <Row className="width-100" gutter={[6,8]}>
                    <Col span={3}>
                        <Text strong>{"{{Date}}"}</Text>
                    </Col>
                    <Col span={7}>
                        <Text>{"{{Concept}}"}</Text>
                    </Col>
                    <Col span={6}>
                        <Text>{"{{Account}}"}</Text>
                    </Col>
                    <Col span={5} className="flex-right">
                        <Text>{"{{Amount}}"}</Text>
                    </Col>
                    <Col span={3} className="flex-right">
                        <ButtonView
                            title="View Details"
                            onClick={() => { }}
                        />
                    </Col>
                </Row>
            </List.Item>
        )
    }

    renderServices = (item) => {
        return (
            <List.Item className="dashboard-services-item">
                <Row className="width-100" gutter={[6,8]} align={"middle"}>
                    <Col span={21}>
                        <Space size={4} direction='vertical'>
                            <Text strong>{"{{ServicesName}}"}</Text>
                            <Text ellipsis>{"{{ServicesName}}"}</Text>
                        </Space>
                    </Col>
                    <Col span={3} className="flex-right">
                        <Tag>Estatus</Tag>
                    </Col>
                </Row>
            </List.Item>
        )
    }


    render() {

        
        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="Dashboard"
                    // subTitle="subtitle"
                />
                <Content className="customer-content dashboard content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Card title={'¡ Bienvenido !'} className="dashboard-welcome">
                                <Row>
                                    <Col span={24} className="center">
                                        
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={24} lg={18}>
                            <Card title={'Ultimas transacciones'} className="dashboard-card">
                                <List
                                    loading={this.state.loading}
                                    className="dashboard-transactions-list"
                                    itemLayout="horizontal"
                                    locale={{ emptyText: "No hay transacciones" }}
                                    dataSource={[{}, {}, {}, {}, {}]}
                                    renderItem={item => this.renderTransaccion(item)}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} md={24} lg={6}>
                            <Card title={'Ultimom pedidos'} className="dashboard-card">
                                <List
                                    loading={this.state.loading}
                                    className="dashboard-services-list"
                                    itemLayout="horizontal"
                                    locale={{ emptyText: "No hay pedidos" }}
                                    dataSource={[{}, {}, {}, {}, {}]}
                                    renderItem={item => this.renderServices(item)}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Content>

            </>
        )
    }
}

export default props => {
    const { setShow } = useSearch()
    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();



    return <Dashboard {...props} setShowSearch={setShow}  navigate={navigate} params={params} />
}


import React, { Component, useState, useContext } from "react";
import { Drawer, List, message, Row, Col, Typography, Divider, Modal, Space } from "antd";
import { Button } from "antd";
import axios from "axios";
import { User, SetUser } from "../../../Hooks/Logged";
import { useCookies } from "react-cookie";

import CardItem from "./CartItem"
import StepPayment from "./StepPayment";
import StepAddress from "./StepAddress";
import StepLogin from "./StepLogin";


import '../../../Styles/Modules/cart.scss'
class Cart extends Component {

    constructor(props) {
        super(props);
    }


    /**
     *
     *
     * @memberof Cart
     */
    deleteItem = (id) => {

        axios.delete('/carrito', {
            params: {
                venta_id: this.props.cookies.cart,
                detalle_id: id
            }
        }).then(({ data }) => {
            console.log('se trajo carrito', data)
            this.props.setCookie("cart", data?._id ?? null)
            this.props.setCart(data)
        }).catch((error) => {
            console.log(error)
        })
    }


    render() {
        return <>
            <List
                className="cart"
                dataSource={Array.isArray(this.props.cart?.detalles) ? this.props.cart?.detalles : []}
                locale={{ emptyText: "Tu carrito esta vacio" }}
                header={
                    <>
                        <Row align={"center"} justify={"space-between"}>
                            <Col span={15} >Productos</Col>
                            <Col span={4}  >Precio</Col>
                            <Col span={1} className="center">Cant.</Col>
                            <Col span={4}  ></Col>
                        </Row>
                    </>
                }
                footer={
                    <>
                        <Row align={"center"} >
                            <Col span={6} className="cart-resume-text">Subtotal</Col>
                            <Col span={18} className="cart-resume-amount">{(this.props.cart.subtotal ?? 0).toMoney(true)}</Col>
                        </Row>
                        {/* <Row align={"center"} >
                            <Col span={6} className="cart-resume-text">Taxes</Col>
                            <Col span={18} className="cart-resume-amount">{(this.props.cart.tax ?? 0).toMoney(true)}</Col>
                        </Row> */}
                        <Row align={"center"} >
                            <Col span={6} className="cart-resume-text">Total</Col>
                            <Col span={18} className="cart-resume-amount">{(this.props.cart.subtotal ?? 0 + this.props.cart.tax ?? 0).toMoney(true)}</Col>
                        </Row>
                    </>
                }
                renderItem={item => <List.Item>
                    <CardItem item={item} cantidad={item.cantidad} onDelete={this.deleteItem} />
                </List.Item>}
            />
        </>
    }
}



export default function DrawerCarrito(props) {
    let user = useContext(User)
    const [step, setStep] = useState(0);
    const [cookies, setCookie] = useCookies(['cart']);

    let next = () => {
        setStep(step + 1)
    }



    return <Modal
        className="cart"
        open={props.open}
        onClose={props.close}
        onCancel={props.close}
        destroyOnClose={true}
        title="Carrito"
        // footer={null}
        footer={<Space>
            <Button type="primary" onClick={() => next()}>Continuar</Button>
        </Space>}
        width={'100%'}
        style={{
            maxWidth: 700//''
        }}
    >
        {step === 0 && <Cart {...props} next={next} user={user} />}
        {step === 1 && <StepLogin {...props} next={next} />}
        {step === 2 && <StepAddress {...props} next={next} user={user} />}
        {step === 3 && <StepPayment {...props} next={next} user={user} setCookie={setCookie} />}

    </Modal>

}
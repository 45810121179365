import React from "react";
import { Row, Col, Typography, } from 'antd';

const { Title, Paragraph } = Typography;

export default function StepEnviar({ folio, nombre }) {

    return (
        <Row justify="center" className='mt-2'>
            <Col xs={24} style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="marketplace-content">
                <Title level={3}>
                    ¡Hemos recibido tu Solicitud!
                </Title>
                
                <Paragraph style={{ maxWidth: 350, textAlign: "center" }}>
                    Muchas gracias por confiar en nosotros, {nombre}
                </Paragraph>
                <Paragraph style={{ margin: "2rem 0 1rem 0" }}>
                    El número de folio de tu pedido es el:
                </Paragraph>
                <Paragraph style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                    {folio}
                </Paragraph>
                <Paragraph style={{ margin: "0rem 0 2rem 0", textAlign: "center" }}>
                    Con tu cuenta Dicofansa, puedes seguir tu cotización en tiempo real.
                </Paragraph>
                
            </Col>
        </Row>
    )
}
import React from 'react'
import { Row, Col, Typography, Button, List } from 'antd';
import { PlusOutlined } from '@ant-design/icons';


import { useContext } from 'react'
import { Carrito, SetCarrito } from '../../../Hooks/Carrito'
import { CardProducto } from '../../Widgets/Cards';
import ModalAgregarProducto from './ModalAgregarProducto'

const { Title, Paragraph } = Typography

class StepCotizador extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            index: null
        }
    }

    delete = (index) => {
        const productos = this.props.productos
        productos.splice(index, 1)

        this.props.setProductos(productos)
    }

    render() {

        return (
            <Row justify="center" align="middle">
                <Col xs={24}>
                    <Title level={3} className="center">
                        Información de cotización
                    </Title>
                </Col>
                <Col xs={24} md={12} className="center">
                    <Title level={4}>Lista de Productos</Title>
                </Col>
                <Col xs={24} md={12} className="center">
                    <Button className="action-add" onClick={() => this.setState({ modalVisible: true })}>
                        Agregar Producto <PlusOutlined />
                    </Button>
                </Col>
                {
                    this.props.productos.length < 1 ? (<Col xs={24}>
                        <Row className='center'>
                            <Paragraph >
                                No hay ningún producto a cotizar
                            </Paragraph>
                        </Row>
                        <Row className='center'>
                            <Button
                                className="center"
                                onClick={() => this.setState({ modalVisible: true })}
                            >
                                ¡Agregar Uno!
                            </Button>
                        </Row>

                    </Col>
                    ) : (
                        <Col xs={24}>
                            <List
                                className='cotizador-productos'
                                //pagination={{}}
                                dataSource={Array.isArray(this.props.productos) ? this.props.productos : []}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <CardProducto
                                            producto={item}
                                            onEditClick={() => this.setState({ index, modalVisible: true })}
                                            onDeleteClick={() => this.delete(index)}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    )
                }

                <ModalAgregarProducto
                    productIdx={this.state.index}
                    visible={this.state.modalVisible}
                    deleteFile={this.deleteFile}
                    onClose={() => {
                        this.setState({ index: null, modalVisible: false })
                    }}
                />
            </Row>
        )
    }

}

export default function View(props) {

    const carrito = useContext(Carrito)
    const setCarrito = useContext(SetCarrito)

    return <StepCotizador {...props} productos={carrito} setProductos={setCarrito} />
}
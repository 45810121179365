import React, { Component, useContext } from "react";
import { Row, Col, Typography, List } from "antd";

import { CardProducto } from '../../Widgets/Cards';
import { Carrito, SetCarrito } from '../../../Hooks/Carrito'


const { Title } = Typography;
class StepConfirmar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Row className='marketplace-cotizador' justify={"center"} align={"middle"} gutter={[24, 24]}>
                <Col xs={24}>
                    <Title level={3} className='cotizador-title'>
                        Información de cotización
                    </Title>
                </Col>
                <Col xs={24} className='cotizador-subtitle'>
                    <Title level={4}>Lista de Productos</Title>
                </Col>
                <Col xs={24}>
                    <List
                        className='cotizador-productos'
                        //pagination={{}}
                        dataSource={this.props.productos}
                        renderItem={(item, index) => (
                            <List.Item>
                                <CardProducto
                                    producto={item}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        )
    }

}


export default function Vista(props) {

    const carrito = useContext(Carrito)
    const setCarrito = useContext(SetCarrito)

    return <StepConfirmar {...props} productos={carrito} setProductos={setCarrito}/>
}
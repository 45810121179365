import React, { useState, useContext, createRef, useEffect } from "react";
import { Button, Row, Layout, message } from "antd";
import axios from "axios";

import { Carrito, SetCarrito } from '../../../Hooks/Carrito'
import User from "../../../Hooks/Logged";
import PageHeader from "../../Widgets/PageHeader/PageHeader";

import StepCotizador from './StepCotizador'
import StepDirecciones from "./StepDirecciones";
import StepConfirmar from "./StepConfirmar";
import StepEnviar from "./StepEnviar";

const { Content } = Layout;

const Cotizador = () => {

    const carrito = useContext(Carrito)
    const setCarrito = useContext(SetCarrito)
    const user = useContext(User)

    const [currentStep, setCurrentStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [folio, setFolio] = useState(null)
    const [contactData, setContactData] = useState(null);


    let refStepInfo = createRef()

    useEffect(() => {
        if (folio && currentStep === 3) next()
    }, [folio])

    useEffect(() => {
        if (contactData && currentStep === 2) next()
    }, [contactData])

    const updateAdressData = (data) => {
        setContactData(data)
    }


    const steps = [
        {
            title: "Cotizar productos",
            content: <StepCotizador />
        },
        {
            title: "Direcciones de envío",
            content: <StepDirecciones updateAdressData={updateAdressData} client={user} />
        },
        {
            title: "Confirmación del pedido",
            content: <StepConfirmar />
        },
        {
            title: "Cotización solicitada",
            content: <StepEnviar nombre={user?.name} folio={folio} />
        }
    ]

    const prev = () => {
        setCurrentStep(currentStep - 1);
    }


    /**
     * Ejecuta el siguiente paso en el proceso si hay productos en el carrito.
     *
     * @return {void}
     */
    const next = async () => {
        console.log("Next executed")
        if (currentStep === 0 && carrito.length < 1) {
            message.info("No hay productos a cotizar")
            return
        }

        if (currentStep === 1 && !contactData) {
            message.error("Faltan datos necesarios para continuar")
            return
        }

        if (currentStep === 2 && !folio) {

            axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
            cotizar()

        }

        setCurrentStep(currentStep + 1);
    }

    const reset = () => {


        setFolio(null)
        setContactData(null)
        setCarrito([])
        setCurrentStep(0)
    }

    const cotizar = () => {
        setLoading(true)
        const cotizacion =
        {
            cliente_id: user?.cliente_id,
            direccion: contactData,
            detalles: carrito,

        }
        console.log(cotizacion)

        // Enviar peticion
        axios.post("/cotizar", { ...cotizacion })
            .then(({ data }) => {
                console.log("data", data);
                setFolio(data.folio);
                setCarrito([])
            })
            .catch(err => {
                console.log("err", err);
            }).finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <PageHeader
                className="admin-page-header"
                title="Cotizador"
            >

            </PageHeader>
            <Content className="pl-1 pr-1">
                {steps[currentStep]?.content}
            </Content>
            <Content className="pl-1 pr-1 mt-1 mb-2">
                {
                    <Row justify={'end'}>
                        <Button
                            className="marketplace-steps-button"
                            disabled={false}
                            loading={loading}
                            onClick={() => next()}
                        >
                            {currentStep === 2 ? 'CONFIRMAR' : 'SIGUIENTE'}
                        </Button>
                    </Row>


                }
            </Content>

        </>

    )
}


export default Cotizador
import { Badge, Button, Card, Col, Collapse, Drawer, Empty, Form, Image, Input, Radio, Row, Spin, Typography, message } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import FormItem from "antd/es/form/FormItem";
import { Component, createRef, useContext, useState } from "react";
import { Link } from "react-router-dom";

import '../../Styles/Modules/Public/productos.scss'
import ProductControl from '../Widgets/ProductControl/ProductControl';
import { ArrowRightOutlined, DeleteOutlined, MenuOutlined, PhoneFilled, ShopFilled, ShoppingCartOutlined } from "@ant-design/icons";
import axios from "axios";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Carrito, SetCarrito } from "../../Hooks/Carrito"
import DrawerCarrito from "./Carrito/Drawer";

import { useCookies } from "react-cookie";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const { Title, Paragraph, Text } = Typography

function InputControlProduct(props) {
    const { limit, onChange } = props
    let [value, setValue] = useState(props.value)


    const onQuantityChange = (index, quantity) => {
        const valueA = parseFloat(quantity)

        if (isNaN(valueA))
            return message.error("No es un numero valido")

        if (valueA > limit)
            return message.error("No hay existencias suficientes.")

        if (valueA <= 0)
            return message.error("Debe ser al menos uno.")

        onChange(valueA)
        setValue(valueA)
    }

    return <ProductControl
        onQuantityChange={onQuantityChange}
        index={null}
        quantity={value}
        className='width-100'
        limit={limit}
        deleteOption={false}

    />
}

class Productos extends Component {


    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
            productos: {
                data: [],
                page: 1,
                limit: 12,
                search: undefined,
                categoria_id: undefined
            },
            categorias: [],
            loadMore: false,
            openCart: false
        }
    }

    formRef = createRef()
    prodRef = createRef()

    componentDidMount() {
        this.getProductos()
        this.getCategorias()
        this.getCart()

        window.addEventListener('scroll', this.scrollHandler)
    }

    getCart = () => {
        if (this.props.cookies.cart) {
            axios.get('/carrito/' + this.props.cookies.cart, {

            }).then(({ data }) => {
                console.log('se trajo carrito', data)
                this.props.setCookie("cart", data?._id ?? null)
                this.props.setCart(data)
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    setVisible = () => {
        console.log("this.state.openCart", this.state.openCart)
        this.setState({ openCart: !this.state.openCart })
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.loadMore !== this.state.loadMore && this.state.loadMore) {
            this.getProductos({ page: this.state.productos.page + 1 })
            ScrollTrigger.refresh()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler)
    }

    scrollHandler = (e) => {
        if (
            document.querySelector('#footer').getBoundingClientRect().top - window.innerHeight <= 0
        ) {
            const { page, limit, data } = this.state.productos
            if (page * limit === data.length) this.setState({ loadMore: true })
        }
    }

    getProductos = ({
        search = this.state.productos.search,
        page = this.state.productos.page,
        limit = this.state.productos.limit,
        categoria_id = this.state.productos.categoria_id
    } = this.state.productos) => {
        this.setState({ loading: true })
        axios.get('/public/productos', {
            params: {
                search, page, limit, categoria_id
            }
        }).then(({ data }) => {
            const productos = page === 1 ? data.data : [...this.state.productos.data, ...data.data]
            this.setState({
                productos: {
                    ...this.state.productos,
                    search,
                    page,
                    limit,
                    categoria_id,
                    data: productos
                }
            })
        }).catch(e => {
            message.error('Error al obtener los productos')
        }).finally(() => {
            this.setState({ loading: false, loadMore: false })
        })
    }

    getCategorias = () => {
        axios.get('/public/categorias')
            .then(({ data }) => {
                this.setState({ categorias: data })
            }).catch(e => {
                message.error('Error al obtener las categorias')
            })
    }

    /**
   *
   *
   * @memberof LandingPage
   * @description Añade un elemento al carrito
   */
    addToCart = (values, producto) => {
        // console.log('add product to cart', values, producto)
        const cart = this.props.cookies.cart;
        axios.post('/carrito', {
            venta_id: (this.props?.cart?.length <= 0) ? null : cart,
            detalles: {
                cantidad: values.cantidad,
                producto_id: producto,
            },
        }).then(({ data }) => {
            console.log(data)
            this.props.setCookie("cart", data._id)
            this.props.setCart(data)
            message.success("Se ha agregado al carrito")

        }).catch((error) => {
            console.log(error)
        })
        console.log("las cookies", this.props.cookies)
        this.props.setCart(cart)

    }




    render() {

        const { categorias, productos, loading } = this.state

        return (
            <div id="productos">
                <nav id="navbar">
                    <Row className="wrapper" gutter={[24, 24]} align="middle">
                        <Col xs={14} lg={4}>
                            <img className="brand" src="/img/logo.svg" />
                        </Col>
                        <Col xs={0} lg={8} className="links">
                            <Link className="link" to={'/'}>Inicio</Link>
                            <Link className="link" to={'/productos'}>Productos</Link>
                        </Col>
                        <Col xs={0} lg={4} className="contact">
                            <a className="link" href={"https://wa.me/8121365636"} target={'_blank'}> <PhoneFilled /> 026-123-456</a>
                        </Col>
                        <Col xs={0} lg={6} className="actions">
                            <Link className="button" to={'#'}>
                                Obtener una Cotización
                                <ArrowRightOutlined />
                            </Link>
                        </Col>
                        <Col xs={2} lg={2} className="actions">
                            <Badge count={this.props.cart?.detalles?.length ?? 0}>
                                <button className="menu" onClick={() => this.setState({ openCart: true })}>
                                    <ShoppingCartOutlined />
                                </button>
                            </Badge>
                        </Col>
                        <Col xs={10} lg={0} className="mobile">
                            <button className="menu" onClick={() => this.setState({ isOpen: true })}>
                                <MenuOutlined />
                            </button>
                        </Col>
                    </Row>
                </nav>
                <main id="main">
                    <section className="header">
                        <Title level={1}>
                            Nuestros Productos
                        </Title>
                    </section>
                    <section id="filters">
                        <Form className="form"
                            ref={this.formRef}

                            onValuesChange={(_, values) => {
                                this.getProductos({ search: values.search, categoria_id: values.categoria_id, page: 1 })
                            }}

                        >
                            <Card className={'form-card'} bordered style={{ marginBottom: 12 }}>
                                <FormItem
                                    name="search"
                                >
                                    <Input size="large" className="search" placeholder="Buscar" />
                                </FormItem>
                            </Card>
                            <Card className={'form-collapse'} bordered>
                                <Collapse bordered={false} ghost className="collapse">
                                    <CollapsePanel header="Categorias" extra={
                                        <Button disabled={!productos.categoria_id}
                                            icon={<DeleteOutlined />}
                                            onClick={() => {
                                                this.formRef?.current?.setFieldsValue({
                                                    categoria_id: undefined
                                                })
                                                this.setState(state => {
                                                    state.productos.categoria_id = undefined
                                                    return state
                                                }, () => this.getProductos({ page: 1 }))
                                            }}
                                        />
                                    }>
                                        <FormItem
                                            name="categoria_id"
                                        >
                                            <Radio.Group className="radio-group">
                                                {categorias.map(c => (
                                                    <Radio key={c._id} value={c._id}>{c.nombre}</Radio>
                                                ))}
                                            </Radio.Group>
                                        </FormItem>
                                    </CollapsePanel>
                                </Collapse>
                            </Card>
                        </Form>
                    </section>
                    <section id="list">
                        {productos?.data?.length > 0 && productos?.data.map(p => (
                            <div className="item" key={p._id}>
                                <div style={{ position: "relative" }}>
                                    <Image

                                        className="image"
                                        {...(p.imagenes?.length > 0 ? ({
                                            src: axios.defaults.baseURL + "/public/productos/" + p._id + "?imagen=" + p.imagenes[0]?.file
                                        }) : ({
                                            placeholder: true
                                        }))}
                                    />
                                </div>
                                <Row>
                                    <Col xs={24} lg={24}>
                                        <Text strong>{p.nombre}</Text> <Text strong>{p.modelo}</Text>
                                    </Col>
                                    <Col xs={24} lg={24} >
                                        <Paragraph style={{ textAlign: "justify" }}>{p.descripcion}</Paragraph>
                                    </Col>
                                    <Col xs={24} lg={24} style={{ justifyContent: "center", display: "flex" }}>
                                        <Text >
                                            $ {p.precio_venta} MXN
                                        </Text>
                                    </Col>
                                    <Col xs={24} lg={24}>
                                        <Form
                                            layout="vertical"
                                            className="pd-0 mt-1"
                                            onFinish={(values) => this.addToCart(values, p._id)}
                                            initialValues={{
                                                cantidad: 1
                                            }}
                                        >
                                            <Form.Item
                                                name="cantidad"
                                                label=""
                                                style={{ marginBottom: 6 }}
                                            >
                                                <InputControlProduct
                                                    quantity={1}
                                                    className='width-100'
                                                    limit={100}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="width-100"
                                                style={{ marginBottom: 6 }}
                                            >
                                                <Button
                                                    htmlType="submit"
                                                    type="primary"
                                                    className="width-100"
                                                    block
                                                    size='small'
                                                    loading={this.state.loading}>
                                                    Agregar al carrito
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        {loading && <div className="spin"><Spin size="large" /></div>}
                        {productos?.data?.length < 1 && !loading && <Empty description={"No se encontraron productos"} />}
                    </section>
                </main>
                <footer id="footer" >
                    <Row className='wrapper' justify={"space-between"} gutter={[40, 40]}>
                        <Col xs={24} sm={12} md={8} lg={9} className='about' >
                            <img src="/img/landing/logo.svg" className='about-logo' />
                            <Paragraph className='about-description'>
                                The process of adding structures to areas of land, also known as real property sites.
                            </Paragraph>
                            <nav className='about-socials'>
                                <a href="https://www.linkedin.com/in/dicofansa-s-a-de-cv-031b0b68/" target='_blank'>
                                    <img src="/img/landing/social/linkedin.svg" />
                                </a>
                            </nav>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} className='offices'>
                            <Text className='offices-title'>Sucursales</Text>
                            <div className='offices-list'>
                                <Paragraph>
                                    Indonesia <br />
                                    Jl. Griya Permata Hijau no D1 Purwomartani Kalasan 543881
                                </Paragraph>
                            </div>
                        </Col>
                    </Row>
                </footer>
                <section id={'overlay'}>
                    <nav className="navbar">
                        <Row className="wrapper" gutter={[24, 24]} align="middle">
                            <Col xs={14} lg={4}>
                                <img className="brand" src="/img/logo.svg" />
                            </Col>
                            <Col xs={0} lg={8} className="links">
                                <Link className="link" to={'/'}>Inicio</Link>
                                <Link className="link" to={'/productos'}>Productos</Link>
                            </Col>
                            <Col xs={0} lg={4} className="contact">
                                <a className="link" href={"https://wa.me/8121365636"} target={'_blank'}> <PhoneFilled /> 026-123-456</a>
                            </Col>
                            <Col className="actions" style={{flex: 1}}>
                                <Link className="button" to={'#'}>
                                    Obtener una Cotización
                                    <ArrowRightOutlined />
                                </Link>
                            </Col>
                            <Col xs={2} lg={2} className="actions">
                                <Badge count={this.props.cart?.detalles?.length ?? 0}>
                                    <button className="menu" onClick={() => this.setState({ openCart: true })}>
                                        <ShoppingCartOutlined />
                                    </button>
                                </Badge>

                            </Col>
                            <Col xs={8} lg={0} className="mobile">
                                <button className="menu" onClick={() => this.setState({ isOpen: true })}>
                                    <MenuOutlined />
                                </button>
                            </Col>
                        </Row>
                    </nav>
                </section>
                <Drawer
                    id="mobile-menu"
                    placement="left"
                    title={null}
                    footer={null}
                    open={this.state.isOpen}
                    onClose={() => this.setState({ isOpen: false })}
                    closable={false}
                    width={280}
                >
                    <div className="links">
                        <Link className="link" to={'/'}>Inicio</Link>
                        <Link className="link" to={'/productos'}>Productos</Link>
                        <a className="link" href={"https://wa.me/8121365636"} target={'_blank'}> <PhoneFilled /> 026-123-456</a>
                    </div>
                    <Link className="button" to={'#'}>
                        Obtener una Cotización
                        <ArrowRightOutlined />
                    </Link>

                </Drawer>

                {this.state.openCart && (
                    <DrawerCarrito
                        open={this.state.openCart}
                        close={this.setVisible}
                        {...this.props}
                    />
                )}
            </div>
        )
    }
}

export default function View() {

    const [cookies, setCookie] = useCookies(['cart']);

    let cart = useContext(Carrito);
    let setCart = useContext(SetCarrito)

    useGSAP(() => {
        const navTween = gsap.fromTo('#overlay > .navbar', {
            y: '-25%',
            opacity: 0,
        }, {
            y: '0%',
            opacity: 1,
            duration: 0.5,
            paused: true
        })

        ScrollTrigger.create({
            start: 'top 300',
            end: 'max',
            onUpdate: self => {
                self.direction === 1 ? navTween.play() : navTween.reverse()
            }
        })
    })

    return <Productos cookies={cookies} setCookie={setCookie} cart={cart} setCart={setCart} />
}
import { Col, Layout, Row, Typography } from 'antd';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardSimpleInfo } from './../../Widgets/Cards';

import '../../../Styles/Modules/Admin/CRM.scss';
import PageHeader from '../../Widgets/PageHeader/PageHeader';

const { Content } = Layout;

/**
 *
 *
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
class Dashboard extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            total: 0,
            ventas: [],
            estatus: [],
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getDashboard()
    }

    /**
     *
     *
     * @memberof Usuarios
     * @method getDashboard
     * @description Obtiene los datos necesarios para la analitica
     */
    getDashboard = () => {
        axios.get('/crm')
            .then(({ data }) => {
                this.setState({...data})
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {

        const labels = [
            'Desistío / Perdido',
            'Registrado',
            'Contactado',
            'Ya ha comprado algo',
            'Pagado'
        ]

        const {total, estatus, ventas} = this.state
        const registrado = estatus.find(e => e._id === 1)
        const compraron = estatus.find(e => e._id === 3)

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="CRM"
                />
                <Content className="admin-content  crm content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} className="">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={8} className="grid">
                                    <CardSimpleInfo
                                        title="Total de Clientes"
                                        info={(total).toMoney(true, false)}
                                    />
                                </Col>
                                <Col xs={24} md={8} className="grid">
                                    <CardSimpleInfo
                                        title="Clientes con Compras"
                                        info={(compraron?.total ?? 0).toMoney(true, false)}
                                    />
                                </Col>
                                <Col xs={24} md={8} className="grid">
                                    <CardSimpleInfo
                                        title="Clientes sin Compras"
                                        info={(registrado?.total ?? 0).toMoney(true, false)}
                                    />
                                </Col>
                            </Row>    
                        </Col>
                        <Col xs={24}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} xl={16}>
                                    <CardSimpleInfo
                                        title="Ventas por Clientes"
                                        info={<ReactECharts
                                            option={{
                                                title: {
                                                  text: ''
                                                },
                                                tooltip: {},

                                                xAxis: {
                                                  data: ventas?.map(v => v.nombre)
                                                },
                                                yAxis: {},
                                                series: [{
                                                    name: 'Ventas',
                                                    type: 'bar',
                                                    data: ventas?.map(v => v.ventas)
                                                }]
                                            }}
                                            style={{ height: 400 }}
                                            opts={{ renderer: 'svg' }}
                                        />}
                                    />
                                </Col>
                                <Col xs={24} xl={8}>
                                    <CardSimpleInfo
                                        title="Estatus de Cotizaciones"
                                        info={<ReactECharts
                                            option={{
                                                tooltip: {
                                                    trigger: 'item'
                                                },
                                                series: [
                                                    {
                                                        name: 'Estatus',
                                                        type: 'pie',
                                                        radius: ['40%', '70%'],
                                                        avoidLabelOverlap: false,
                                                        itemStyle: {
                                                            borderRadius: 0,
                                                            borderColor: '#fff',
                                                            borderWidth: 2
                                                        },
                                                        label: {
                                                            show: false,
                                                            position: 'center'
                                                        },
                                                        labelLine: {
                                                            show: false
                                                        },
                                                        data: estatus?.map(e => ({name: labels[e._id], value: e.total}))
                                                    }
                                                ]
                                            }}
                                            style={{ height: 400 }}
                                            opts={{ renderer: 'svg' }}
                                        />}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Content>

            </>
        )
    }
}

export default props => {

    const navigate = useNavigate()

    return <Dashboard {...props} navigate={navigate}/>
}
import React from 'react';
import { Card, Row, Col, Avatar, Typography, Button, Space } from 'antd';
import { DeleteOutlined } from "@ant-design/icons"
const { Text, Paragraph } = Typography;



const renderImage = (id, images, model) => {
  if (images && images.length > 0)
    return `${process.env.REACT_APP_WEB_SERVICE}/${model}/${id}?imagen=${images[0]?.file}`
  return `${process.env.REACT_APP_WEB_SERVICE}/images/default.png`
}

/**
 *
 *
 * @param {*} { item, cantidad, onDelete }
 * @returns 
 */
function CartElement({ item, cantidad, onDelete }) {
  let { _id, variante_id, servicio_id, precio, producto_id } = item;
  console.log(item)
  let nombre = "";
  let descripcion = "";
  let imagenes = [];
  let model = ""
  let id = "";
  if (producto_id) {
    model = "product";
    nombre = producto_id.nombre;
    descripcion = producto_id.descripcion;
    imagenes = producto_id.imagenes;
    id = producto_id._id;

  }
  if (servicio_id) {
    model = "service";
    nombre = servicio_id.nombre;
    descripcion = servicio_id.descripcion;
    imagenes = servicio_id.imagenes;
    id = servicio_id._id;
  }
  return (
    <Card className='width-100'>
      <Row gutter={[8, 0]} justify={"space-between"}>
        <Avatar shape='square' size={50}
          src={renderImage(id, imagenes, model)}
          alt={nombre} />
        <Col span={10}>
          <Text strong>{nombre}</Text>
          <Text >{variante_id?.nombre}</Text>
          <Paragraph style={{ textAlign: "justify" }}>{descripcion}</Paragraph>
            {/* <Paragraph ellipsis className='text-gray'>{descripcion}</Paragraph> */}
          {/* <div>
          </div> */}
        </Col>
        <Col span={4} style={{ justifyContent: "end", textAlign: "end" }}>
          <Text type="secondary">{precio ? `$ ${precio}` : null}</Text>
        </Col>
        <Col span={4}  style={{ justifyContent: "end", textAlign: "end" }}>
          <Text type="secondary">{cantidad}</Text>
        </Col>
        <Button type="primary" size='small' danger icon={<DeleteOutlined />} onClick={() => onDelete(_id)}></Button>
      </Row>
    </Card>
  );
}

export default CartElement;
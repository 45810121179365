import { Carousel, Col, Divider, Drawer, Layout, Row, Typography } from 'antd';
import React, { Component, createRef, useRef } from 'react';

// Components
import { ArrowRightOutlined, MenuOutlined, PhoneFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { useGSAP } from '@gsap/react';

// import '';

import '../../Styles/Modules/Landing/landing.scss';


const { Title, Text, Paragraph } = Typography
const { Header, Content, Footer } = Layout

gsap.registerPlugin(useGSAP, ScrollTrigger)

const Animation = (props) => {

  useGSAP((context, contextSafe) => {
    // ELEMENTS
    const video = document.querySelector('#welcome .video video')
    const videoBtn = document.querySelector('#welcome .video .button')
    const navbar = document.querySelector('#overlay-navbar')

    // EVENTS
    // Navbar
    const navTween = gsap.fromTo(navbar, {
      y: '-100%',
      opacity: 0,
    }, {
      y: '0%',
      opacity: 1,
      duration: 0.5,
      paused: true
    })

    ScrollTrigger.create({
      start: 'top 300',
      end: 'max',
      onUpdate: self => {
        self.direction === 1 ? navTween.play() : navTween.reverse()
      }
    })

    // Video
    const toggleVideo = contextSafe(() => {
      if (video.paused) {
        gsap.to(videoBtn, {
          opacity: 0,
          display: 'none',
          scale: 0.5,
          duration: 0.5
        })
        video.play()
      } else {
        gsap.to(videoBtn, {
          opacity: 1,
          display: 'flex',
          scale: 1,
          duration: 0.5
        })
        video.pause()
      }
    })
    video.addEventListener('click', toggleVideo)
    videoBtn.addEventListener('click', toggleVideo)

    // EFFECTS
    const fadeUp = {
      from: {
        y: '25%',
        opacity: 0
      },
      to: {
        y: '0%',
        opacity: 1,
        duration: 0.7,
        stagger: 0.2
      }
    }
    const fadeDown = {
      from: {
        y: '-25%',
        opacity: 0
      },
      to: {
        y: '0%',
        opacity: 1,
        duration: 0.7,
        stagger: 0.2
      }
    }
    const fadeLeft = {
      from: {
        x: '-25%',
        opacity: 0
      },
      to: {
        x: '0%',
        opacity: 1,
        duration: 0.7,
        stagger: 0.2
      }
    }
    const fadeRight = {
      from: {
        x: '25%',
        opacity: 0
      },
      to: {
        x: '0%',
        opacity: 1,
        duration: 0.7,
        stagger: 0.2
      }
    }

    // ANIMATIONS

    // Header
    const header = gsap.timeline({ delay: 1 })
    header
      .fromTo('#header #navbar', fadeDown.from, fadeDown.to)
      .fromTo('#header #welcome .content', fadeLeft.from, fadeLeft.to, '<')
      .fromTo('#header #welcome .video video', fadeRight.from, fadeRight.to, '<');

    // Experience
    const experience = gsap.timeline({
      scrollTrigger: {
        trigger: '#header #welcome',
        start: 'bottom center',
        end: "+=100"
      }
    })
    experience
      .fromTo('#experience .data', fadeDown.from, fadeDown.to)

    // Services
    const services = gsap.timeline({
      scrollTrigger: {
        trigger: '#experience',
        start: 'bottom 400',
        end: "+=300"
      },
      delay: 0.5
    })
    services
      .fromTo('#services .bg .bg-orange', fadeRight.from, fadeRight.to)
      .fromTo('#services .image', fadeLeft.from, fadeLeft.to, '<')
      .fromTo('#services .article', fadeDown.from, fadeDown.to, '<')

    // Alcance
    const alcance = gsap.timeline({
      scrollTrigger: {
        trigger: '#services',
        start: 'bottom 400',
        end: "+=200"
      },
      delay: 0.5
    })
    alcance
      .fromTo('#alcance .gallery', fadeLeft.from, fadeLeft.to)
      .fromTo('#alcance .header', fadeRight.from, fadeRight.to, '<')

    // Tools
    const tools = gsap.timeline({
      scrollTrigger: {
        trigger: '#alcance',
        start: 'bottom 400',
        end: "+=200"
      },
      delay: 0.5
    })
    tools
      .fromTo('#tools .gallery .image', fadeDown.from, fadeDown.to)
      .fromTo('#tools .header', fadeRight.from, fadeRight.to, '<')

    // About
    const about = gsap.timeline({
      scrollTrigger: {
        trigger: '#tools',
        start: 'bottom 400',
        end: "+=400"
      },
      delay: 0.5
    })
    about
      .fromTo('#about .header', fadeDown.from, fadeDown.to)
      .fromTo('#about .content .card', fadeDown.from, fadeDown.to, '<')


    // CTA
    const cta = gsap.timeline({
      scrollTrigger: {
        trigger: '#about',
        start: 'center 400',
        end: "+=200"
      },
      delay: 0.5
    })
    cta
      .fromTo('#cta .left', fadeRight.from, fadeRight.to)
      .fromTo('#cta .right', fadeLeft.from, fadeLeft.to, '<')


    // About
    const contact = gsap.timeline({
      scrollTrigger: {
        trigger: '#cta',
        start: 'bottom 80%',
        end: "+=100"
      },
      delay: 0.5
    })
    contact
      .fromTo('#contact .wrapper .ant-col', fadeDown.from, fadeDown.to)

    return () => {
      //Video
      video.removeEventListener('click', toggleVideo)
      videoBtn.removeEventListener('click', toggleVideo)
    }

  })

  return props.children
}

/**
 * 
 * @class Landing
 * @extends {Component}
 * @description Componente Landing page
 */
class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: []
    }
  }

  carouselHeader = createRef(null)


  toggleVideo = (btn, video) => {
    return () => {
      console.log(btn.classList, video.paused)
      btn.classList.toggle('hide')
      video.paused ? video.play() : video.pause()
    }
  }

  render() {

    const carouselSettings = {
      infinite: false,
      slidesToShow: 3,
      responsive: [{
        breakpoint: 1300,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 900,
        settings: {
          slidesToShow: 1
        }
      }, {
        breakpoint: 650,
        settings: {
          slidesToShow: 1
        }
      }]
    }

    const industrias = [{
      name: 'Industrial',
      icon: 'industrial',
      desc: 'Proporcionamos soluciones avanzadas para sistemas de automatización y maquinaria en procesos industriales.'
    }, {
      name: 'Agrícola',
      icon: 'agricola',
      desc: 'Ofrecemos equipos y sistemas hidráulicos que optimizan las operaciones agrícolas, desde la irrigación hasta la maquinaria de cosecha.'
    }, {
      name: 'Moviles',
      icon: 'moviles',
      desc: 'Suministramos componentes y sistemas para vehículos de transporte, mejorando la logística y mantenimiento de flotas.'
    }, {
      name: 'Forestal',
      icon: 'forestal',
      desc: 'Brindamos soluciones en maquinaria y equipos para la gestión forestal, aumentando la eficiencia en la explotación de recursos madereros.'
    }, {
      name: 'Petroleo y Gas',
      icon: 'petroleo-gas',
      desc: 'Proveemos tecnología y componentes para la extracción y procesamiento en la industria del petróleo y gas.'
    }, {
      name: 'Navales',
      icon: 'navales',
      desc: 'Ofrecemos soluciones para la industria naval, incluyendo sistemas hidráulicos para barcos y equipos de mantenimiento marítimo.'
    }, {
      name: 'Minería',
      icon: 'mineria',
      desc: 'Suministramos equipos robustos y confiables para la minería, facilitando la extracción y el procesamiento de minerales.'
    }, {
      name: 'Energia',
      icon: 'energia',
      desc: 'Proporcionamos componentes esenciales para la generación y distribución de energía, incluyendo el sector de energías renovables.'
    }]

    return (
      <Animation>
        <Layout id="landing">
          <header id="header">
            <nav id="navbar">
              <Row gutter={[36, 36]} className='wrapper' align={"middle"} justify={"space-between"}>
                <Col xs={16} md={12} lg={12} xl={5}>
                  <img src="/img/landing/logo.svg" className='logo' />
                </Col>
                <Col xs={0} xl={14} className='links'>
                  <a href={"#header"} className='link'>Inicio</a>
                  <a href={"#about"} className='link'>Nosotros</a>
                  <a href={"#services"} className='link'>Servicios</a>
                  <Link to={'/productos'} className='link' style={{
                    background: "red",
                    padding: "4px 6px",
                    borderRadius: 4
                  }}>Productos</Link>
                  <a href={"#contact"} className='link'>Contacto</a>
                  <a href={"https://wa.me/+528119451744"} target={'_blank'} className='link'> <PhoneFilled /> +52 (811) 945-1744</a>
                </Col>
                <Col xs={8} md={12} lg={12} xl={5} className='actions'>
                  <a target='_blank' href="mailto:udnmty@dicofansa.com?subject=%C2%A1Quiero%20solicitar%20una%20cotizaci%C3%B3n!" className='actions-quote'>
                    Obtener una cotizacion
                    <ArrowRightOutlined />
                  </a>
                  <MenuOutlined
                    className="actions-menu"
                    onClick={() => this.setState({ menuOpen: true })}
                  />
                </Col>
              </Row>
            </nav>
            <section id="welcome">
              <div className='content'>
                <div className="wrapper">
                  <Text className='subtitle'>
                    ESPECIALISTAS EN MAQUINARIA Y MATERIAL HIDRAULICO
                  </Text>
                  <Title level={1} className='title'>
                    Servicio de calidad en instalacion Ole-hidraulicas e Hidraulicas, sistemas de lubricacion y Neumaticos
                  </Title>
                  <Text className='description'>
                    Somos expertos en dar servicio de calidad en instalacion Ole-hidraulicas e Hidraulicas,
                    sistemas de lubricacion y Neumaticos para toda la industria en general.
                  </Text>
                  <div className='links'>
                    <Link to="mailto:udnmty@dicofansa.com?subject=%C2%A1Quiero%20solicitar%20una%20cotizaci%C3%B3n!" className='link'>
                      Obtener cotizacion
                    </Link>
                    <Link to="#" className='link'>
                      Sobre Nosotros
                    </Link>
                  </div>
                </div>
              </div>
              <div className="video">
                <video loop={true} autoPlay muted>
                  <source src='/video/placeholder.mp4' type="video/mp4" />
                </video>
                <button className='button'>
                  <div>
                    <img src="/img/landing/play.svg" />
                  </div>
                </button>
              </div>
            </section>

          </header>
          <main className='landing-main main'>
            <section id='experience'>
              <Row gutter={[12, 12]} justify={"center"} className='wrapper'>
                <Col xs={24} lg={7} className='data'>
                  <Text className='number'>400+</Text>
                  <Text className='type'>Productos</Text>
                  <Text className='subtype'>Especializados</Text>
                </Col>
                <Col xs={12} lg={1} className='div'>
                  <Divider className='div-vertical' type="vertical" />
                  <Divider className='div-horizontal' type="horizontal" />
                </Col>
                <Col xs={24} lg={8} className='data'>
                  <Text className='number'>200+</Text>
                  <Text className='type'>Ventas</Text>
                  <Text className='subtype'>de Producto</Text>
                </Col>
                <Col xs={12} lg={1} className='div'>
                  <Divider className='div-vertical' type="vertical" />
                  <Divider className='div-horizontal' type="horizontal" />
                </Col>
                <Col xs={24} lg={7} className='data'>
                  <Text className='number'>10+</Text>
                  <Text className='type'>Años</Text>
                  <Text className='subtype'>de Experiencia</Text>
                </Col>
              </Row>
            </section>
            <section id="services">
              <Row className='bg'>
                <Col xs={24} lg={8}>
                </Col>
                <Col xs={24} lg={16} className='bg-orange'>
                </Col>
              </Row>
              <Row className='content'>
                <Col xs={24} lg={11} xl={12} className="image">
                  <img src="/img/landing/wwd.png" className='thumb' />
                </Col>
                <Col xs={24} lg={13} xl={12} className="article">
                  <Text className='subtitle'>LO QUE HACEMOS</Text>
                  <Title level={2} className='title'>Proveer soluciones de calidad garantizada.</Title>
                  <Paragraph>
                    DICOFANSA fue creada para dar servicio de calidad en instalacion Ole-hidraulicas e Hidraulicas, sistemas de lubricacion y Neumaticos para toda la industria en general, ofreciendo un costo beneficio en los servicios proporcionados ,asi mismo siendo una empresa proveedora en el suministro de equipos, productos y repuestos para la industria Siderurgica, Minera,, Cementera, Papelera, Textil, Automotriz, Hidrocarburos, Naval, Aerea,etc.
                  </Paragraph>
                  <blockquote>
                    Servicio de calidad con expertos calificados.
                  </blockquote>
                  <Paragraph>
                    Para DICOFANSA lo mas importante son nuestros clientes por eso ofrecemos productos y servicios de la mejor calidad a un costo beneficio. En DICOFANSA contamos con personal calificado para darle una mejor atencion, servicio y asesoria tecnica especializada.

                    {/* Lo anterior garantiza que nuestro servicio cumpla con su requerimiento. Ofrecemos a su disposición la experiencia de 20 años en el mercado dando servicio a las diferentes empresas tanto locales como foráneas. */}
                  </Paragraph>
                </Col>
              </Row>
            </section>
            <section id={'alcance'} >
              <Row gutter={[80, 80]} className='wrapper' align={'middle'}>
                <Col xs={24} lg={12} className='gallery'>
                  <img className='image' src="/img/landing/valves.jpg" />
                </Col>
                <Col xs={24} lg={12}>
                  <div className='header'>
                    <Text className='subtitle'>NUESTRA MISIÓN</Text>
                    <Title className='title' level={2}>
                      Soluciones integrales de alta calidad en productos y servicios hidraulicos, neumaticos, sistemas de lubricacion y control de fluido
                    </Title>
                    <div className='description'>
                      <p>
                        Proveer soluciones integrales de alta calidad en productos y servicios hidraulicos, neumaticos, sistemas de lubricacion y control de fluidos en la industria en gral a nivel nacional e internacional con etica, profesionalismo, con personal comprometido, calificado y capacitado, garantizando la satisfaccion total del cliente.
                        <br />
                        Durante nuestra trayectoria hemos trabajado con las principales empresas en el área metropolitana, y foráneas Importantes.
                      </p>
                      <Text>NUESTROS SERVICIOS</Text>
                      {/* 
Soporte tecnico comercial e ingenieria
Sistemas hidraulicos y Neumaticos
Sistemas de lubricacion grasa aceite
Instalacion de equipos hidraulicos
Reparacion de cilindros hidraulicos
Fabricacion de tanques para aceite hidraulico
Diseño y desarrollo de equipos contra incendio
Distribucion de productos quimicos para la Industria en Gral
 */}

                      <ul>
                        <li>
                          Montaje e instalacion de productos tipicos y equipos
                        </li>
                        <li>
                          Soporte tecnico comercial e ingenieria
                        </li>
                        <li>
                          Sistemas de lubricacion grasa aceite
                        </li>
                        <li>
                          Instalacion de equipos hidraulicos
                        </li>
                        <li>
                          Reparacion de cilindros hidraulicos
                        </li>
                        <li>
                          Fabricacion de tanques para aceite hidraulico
                        </li>
                        <li>
                          Diseño y desarrollo de equipos contra incendio
                        </li>
                        <li>
                          Distribucion de productos quimicos para la Industria en Gral
                        </li>
                        <li>
                          Servicio de maquinado de piezas especiales
                        </li>
                        {/* <li>Compromiso con nuestros clientes</li>
                        <li>Vision estrategica a largo plazo</li>
                        <li>Profesionalismo, compromiso y tenacidad</li>
                        <li>Compromiso con nuestro personal</li>
                        <li>Excelencia y calidad en nuestro servicios</li>
                        <li>Conocimiento, Creatividad y Disciplina</li>
                        <li>Responsabilidad con nuestro trabajo</li>
                        <li>Espiritu de servicio</li> */}
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </section>
            <section id={'tools'}>
              <Row gutter={[80, 80]} className='wrapper' align={'middle'}>
                <Col xs={24} xl={12}>
                  <div className='header'>
                    <Text className='subtitle'>NUESTRA VISIÓN</Text>
                    <Title className='title' level={2}>
                      Comercializacion e instalacion de materiales hidraulicos, neumaticos, de lubricacion
                    </Title>
                    <Paragraph className='description'>
                      Ser una compañia lider en el servicio comercializacion e instalacion de materiales hidraulicos, neumaticos, de lubricacion para la industria en general
                    </Paragraph>
                  </div>
                </Col>
                <Col xs={24} xl={12} className='gallery'>
                  <img className='image' src="/img/landing/img2.png" />
                  <img className='image' src="/img/landing/img3.png" />
                  <img className='image' src="/img/landing/img4.png" />
                </Col>
              </Row>
            </section>
            <section id="about">
              <div className='header'>
                <Text className='subtitle'>NOSOTROS</Text>
                <Title className='title' level={2}>Alta Calidad en Productos Industriales</Title>
                <Paragraph className='description'>
                  DICOFANSA ofrece servicios de alta calidad en instalaciones Oleo-Hidráulicas e Hidráulicas, sistemas de lubricación, y manejo de Hidrocarburos. Somos líderes en proporcionar soluciones costo-efectivas para la Industria Siderúrgica, Minera, Cementera, Papelera, Textil, Automotriz, y más. Además, somos proveedores clave de productos y repuestos
                </Paragraph>
              </div>
              <Row className='content' gutter={[30, 30]} justify={"center"}>
                {
                  industrias.map(({ name, icon, desc }, i) => (
                    <Col xs={24} sm={12} lg={8} xl={6} key={`${icon}-${i}`}>
                      <div className='card' >
                        <Text className='card-title'>{name}</Text>
                        <img className='card-icon' src={`/img/landing/industrias/${icon}.svg`} />
                        <Paragraph className='card-desc'>
                          {desc}
                        </Paragraph>
                      </div>
                    </Col>
                  ))
                }
              </Row>
            </section>

            <section id={'tools'}>
              <Row gutter={[80, 80]} className='wrapper' align={'middle'}>
                <Col xs={24} xl={24}>
                  <div className='header'>
                    <Text className='subtitle'>NUESTRA VISIÓN</Text>
                    <Title className='title nowidth' >
                      Comercializacion e instalacion de materiales hidraulicos, neumaticos, de lubricacion
                    </Title>
                    <Paragraph className='description'>
                      Ser una compañia lider en el servicio comercializacion e instalacion de materiales hidraulicos, neumaticos, de lubricacion para la industria en general
                    </Paragraph>
                  </div>
                </Col>
              </Row>
            </section>
            <section id="cta">
              <Row gutter={[24, 24]} className='wrapper' align={"middle"}>
                <Col xs={24} md={12} className='left'>
                  <Text className='title'>¿Quieres más información?</Text> <br />
                  <Text className='subtitle'>Contactanos a través de nuestra WhatsApp</Text>
                </Col>
                <Col xs={24} md={12} className='right'>
                  <a href={"https://api.whatsapp.com/send?phone=528119451744"} target='_blank' className='button' >
                    Comunícate con nosotros
                  </a>
                </Col>
              </Row>
            </section>
          </main>
          <footer id="contact">
            <Row className='wrapper' gutter={[40, 40]} justify={"space-between"}>
              <Col xs={24} lg={6} className='about' >
                <img src="/img/landing/logo.svg" className='about-logo' />

                {/* Reparacion de cilindros hidarulicos
Servicio de maquinado de piezas
Subestaciones eléctricas en 15,23 y 34 Kv
Misceláneos como: transformadores, cables de potencia en las marcas VIAKON, CONDUCTORES DEL NORTE, KOBREX, CONDUMEX Y GENERAL CABLE
Accesorios de conexion ELASTIMOLD, BURNDY, RAYCHEM Y 3M */}
                <Paragraph className='about-description'>
                Reparacion de cilindros hidarulicos <br/>
                Servicio de maquinado de piezas <br/>
                Subestaciones eléctricas en 15,23 y 34 Kv <br/>
                {/* 
                Subestaciones eléctricas en 15,23 y 34 Kv <br/>
                Misceláneos como: transformadores, cables de potencia en las marcas VIAKON, CONDUCTORES DEL NORTE, KOBREX, CONDUMEX Y GENERAL CABLE <br/>
                Accesorios de conexion ELASTIMOLD, BURNDY, RAYCHEM Y 3M <br/> */}
                </Paragraph>
                <nav className='about-socials'>
                  <a href="https://www.linkedin.com/in/dicofansa-s-a-de-cv-031b0b68/" target='_blank'>
                    <img src="/img/landing/social/linkedin.svg" />
                  </a>
                </nav>
              </Col>
              <Col xs={24} sm={12} lg={8} className='offices'>
                <Text className='title'>Sucursales</Text>
                <div className='offices-list'>
                  <Paragraph>
                    {/* Indonesia <br />
                    Jl. Griya Permata Hijau no D1 Purwomartani Kalasan 543881 */}
                    Misceláneos como: transformadores, cables de potencia en las marcas VIAKON, CONDUCTORES DEL NORTE, KOBREX, CONDUMEX Y GENERAL CABLE <br/>
                    Accesorios de conexion ELASTIMOLD, BURNDY, RAYCHEM Y 3M <br/>
                  </Paragraph>
                </div>
              </Col>
            </Row>
          </footer>
          <nav id="overlay-navbar">
            <Row gutter={[36, 36]} className='wrapper' align={"middle"} justify={"space-between"}>
              <Col xs={16} md={12} lg={12} xl={5}>
                <img src="/img/landing/logo.svg" className='logo' />
              </Col>
              <Col xs={0} xl={14} className='links'>
                <a href={"#header"} className='link'>Inicio</a>
                <a href={"#about"} className='link'>Nosotros</a>
                <a href={"#services"} className='link'>Servicios</a>
                <Link to={'/productos'} className='link'>Productos</Link>
                <a href={"#contact"} className='link'>Contacto</a>
                <a href={"https://wa.me/+528119451744"} target={'_blank'} className='link'> <PhoneFilled /> +52 (811) 945-1744</a>
              </Col>
              <Col xs={8} md={12} lg={12} xl={5} className='actions'>
                <a target='_blank' href="mailto:udnmty@dicofansa.com?subject=%C2%A1Quiero%20solicitar%20una%20cotizaci%C3%B3n!" className='actions-quote'>
                  Obtener una cotizacion
                  <ArrowRightOutlined />
                </a>
                <MenuOutlined
                  className="actions-menu"
                  onClick={() => this.setState({ menuOpen: true })}
                />
              </Col>
            </Row>
          </nav>
          <Drawer
            open={this.state.menuOpen}
            onClose={() => this.setState({ menuOpen: false })}
            id="overlay-menu"
            placement='left'
            title={null}
            closeIcon={null}
            width={220}
          >
            <nav className='menu-links'>
              <Link to={"#"} className='menu-link'>Inicio</Link>
              <Link to={"#about"} className='menu-link'>Nosotros</Link>
              <Link to={"#services"} className='menu-link'>Servicios</Link>
              {/* <Link to={"#"} className='menu-link'>Productos</Link> */}
              <Link to={"#contact"} className='menu-link'>Contacto</Link>
              <Link to="https://wa.me/+528119451744" className='menu-link'> <PhoneFilled /> +52 (811) 945-1744</Link>
            </nav>
            <a target='_blank' href="mailto:udnmty@dicofansa.com?subject=%C2%A1Quiero%20solicitar%20una%20cotizaci%C3%B3n!" className='menu-quote'>
              Obtener una cotizacion
              <ArrowRightOutlined />
            </a>
          </Drawer>
        </Layout>
      </Animation>

    )
  }
}


export default function (props) {

  return <Landing {...props} />
}
import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin } from 'antd';

import axios from "axios"
import Busqueda from "../../Widgets/Busqueda";


class ModalDirecciones extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            address: null
        }
    }

    refModalDirecciones = React.createRef();

    componentDidMount() {
        

        if (this.props.direccion_id !== undefined) {
            this.get()
        }
    }

    /**
     * @memberof ModalDirecciones
     * @method get
     * @description Obtiene una direccion de la DB
     *
     */
    get = () => {
        this.setState({ loading: true })
        axios.get('/direccion/'+ this.props.direccion_id).then(response => {
            let direccion = response.data;
            this.refModalDirecciones.current.setFieldsValue({
                ...direccion
            })

        }).catch(error => {
            message.error('Error getting address')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalDirecciones
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        values.id = this.props.direccion_id
        values.cliente_id = this.props.cliente_id

        if (this.props.direccion_id) {
            this.updateCuenta(values)
        } else {
            this.addCuenta(values)
        }

    }

    /**
     * @memberof ModalDirecciones
     * @method addCuenta
     * @description Añade una direccion a la BD
     *
     */
    addCuenta = (values) => {
        axios.post('/direcciones', {
            ...values
        }).then(response => {
            message.success('Address created!')
            this.props.onClose()
        }).catch(error => {
            message.error('Error creating address')
            console.log(error)
        })
    }

    /**
     * @memberof ModalDirecciones
     * @method updateCuenta
     * @description Actualiza la información de una direccion
     * 
     */
    updateCuenta = (values) => {
        this.setState({ loading: true })
        axios.put('/direcciones/', {
            ...values
        }).then(response => {
            message.success('Address updated')
            this.props.onClose()
        }).catch(error => {
            message.error('Error updating address')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        return (
            <Form
                layout="vertical"
                name="form-direccion"
                id="form-direccion"
                ref={this.refModalDirecciones}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Alias"
                                name="alias"
                                rules={[{
                                    required: true,
                                    message: "Ingrese un alias para su dirección"
                                }]}
                            >
                                <Input placeholder="Alias" ></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Código Postal"
                                name = 'codigo_postal'
                                rules={[{
                                    required: true,
                                    message: "Introduzca su código postal"
                                }]}
                            >
                                <Input placeholder="Código Postal" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Dirección"
                                name = 'direccion'
                                rules={[{
                                    required: true,
                                    message: "Introduzca su dirección"
                                }]}
                            >
                                <Input placeholder="Dirección" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Ciudad"
                                name = 'ciudad'
                                rules={[{
                                    required: true,
                                    message: "Introduzca su ciudad"
                                }]}
                            >
                                <Input placeholder="Ciudad" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Entidad Federativa"
                                name = 'estado'
                                rules={[{
                                    required: true,
                                    message: "Introduzca su entidad federativa"
                                }]}
                            >
                                <Input placeholder="Entidad Federativa" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="País"
                                name = 'pais'
                                rules={[{
                                    required: true,
                                    message: "Introduzca su país"
                                }]}
                            >
                                <Input placeholder="Entidad Federativa" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Especificaciones"
                                name = 'especificaciones'
                            >
                                <Input.TextArea placeholder="Especificaciones" rows={2} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Notas"
                                name = 'notas'
                            >
                                <Input.TextArea placeholder="Notas" rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, direccion_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={direccion_id ? "Edit Address" : "Create Address"}
        destroyOnClose={true}
        cancelText="Cancel"
        okText="Save"
        okButtonProps={{ form: 'form-direccion', key: 'submit', htmlType: 'submit' }}
    >
        <ModalDirecciones {...props} />
    </Modal>

}
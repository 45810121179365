import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Typography, Modal, Select, Checkbox, Spin, Switch, message, Result } from 'antd';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { User, SetUser } from '../../../Hooks/Logged';
import axios from 'axios'

//css
import '../../../Styles/Global/auth.scss';
import PhoneInput from '../../Widgets/Inputs/PhoneInput';
import { WhatsAppOutlined } from '@ant-design/icons';
// import Paragraph from 'antd/es/skeleton/Paragraph';

import CarritoHook, { SetCarrito } from '../../../Hooks/Carrito';
import { Cookies } from 'react-cookie';

// C:\var\www\dicofansa-web\src\Hooks\Carrito.js

const clearAllCookies = () => {
	const cookies = new Cookies();
	const allCookies = cookies.getAll();

	Object.keys(allCookies).forEach(cookie => {
		cookies.remove(cookie, { path: '/' });
	});
};
//Modales
// import ModalAvisoPrivacidad from "../../Auth/ModalAvisoPrivacidad"

const { Title, Text, Paragraph } = Typography

/**
 * 
 * @class Register
 * @extends {Component}
 * @description Componente para registrarse
 */
class Register extends Component {


	static contextType = CarritoHook

	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
	}

	componentDidMount() {
		console.log("contextType", this.context)
	}

	/**
	 * @memberof Register
	 *
	 * @method handleSubmit
	 * @description  Envia los datos del formulario al Servidor
	 **/
	handleSubmit = (values) => {
		this.setState({ loading: false })
		axios.post('/register', {
			...values,
			venta_id: this.context?._id
		})
			.then(({ data, headers }) => {
				clearAllCookies();
				this.setState({ loading: false })
				if (values.crear_cuenta) {
					// Iniciar sesión
					axios.defaults.headers.post["Authorization"] = headers.authorization;
					sessionStorage.setItem('token', headers.authorization);
					axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
					this.props.setUser(data.user);
					message.success("Cuenta creada y sesión iniciada exitosamente");
					this.goToNextPage();
				} else {
					message.success("Cliente creado exitosamente. Redirigiendo a WhatsApp...");
					const nombre = values.nombre;  // Reemplaza con el valor real de 'nombre'

					const mensaje = `Que tal! mi nombre es ${nombre}, me gustaría solicitar información`;
					const adicional = ` de mi solicitud ${data?.venta?.folio}`;


					const whatsappMessage = encodeURIComponent(mensaje + ((data?.venta?.folio) ? adicional : ""));
					const whatsappUrl = `https://api.whatsapp.com/send?phone=528119451744&text=${whatsappMessage}`;
					window.open(whatsappUrl, "_blank");
				}

				this.props?.setCarrito([])
				this.props.close()
				Modal.success({
					title: 'Operación exitosa',
					content: (
						<Result
							status="success"
							title="Operación Completada"
							subTitle={"Hemos enviado su solicitud, recibirá una respuesta en 48 horas."}
						/>
					),
					onOk() {
						console.log('Modal cerrado');
					},
				});
			})
			.catch((error) => {
				console.log("error", error);
				Modal.error({
					title: "An error occurred in the registry",
					content: error?.response?.data?.message ?? "Try later"
				})
				this.setState({ loading: false });
			})
	};

	/**
	 * @memberof Cart
	 * @method goToNextPage
	 * @description indica a que step tiene que ir
	 */
	goToNextPage = () => {
		let page = 1

		//veriicamos si tiene productos en el carrito
		let productos = false
		for (let detalle of this.props.cart?.detalles) {
			if (detalle.variante_id) productos = true
		}

		if (productos) page = 1

		this.props.next(page)
	}


	render() {
		return (
			<>
				<Spin spinning={this.state.loading}>
					<Form
						onFinish={this.handleSubmit}
						layout={"vertical"}
						requiredMark={false}
						className='login-form'
						initialValues={{
							crear_cuenta: false
						}}
					>
						<Title level={3} className="h3-title mb-1">

							<Form.Item
								noStyle
								shouldUpdate={(prevValues, currentValues) => prevValues.crear_cuenta !== currentValues.crear_cuenta}
							>
								{({ getFieldValue }) =>
									getFieldValue('crear_cuenta') ? "Solicitar y Registrarse en Dicofansa" : "Solicitar Cotización"
								}
							</Form.Item>


						</Title>
						<Row gutter={[8, 0]}>
							<Col span={12}>
								<Form.Item
									name="nombre"
									label="Nombre"
									rules={[
										{ required: true, message: 'Por favor, ingrese su nombre' },
										{
											max: 50,
											message: "Máximo de 128 caracteres"
										}
									]}>
									<Input size="large" className="input-login" />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									name="apellidos"
									label="Apellidos"
									rules={[
										{ required: true, message: 'Por favor, ingrese su apellido' },

										{
											max: 50,
											message: "Máximo de 128 caracteres"
										}
									]}>
									<Input size="large" className="input-login " />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									name="email"
									label="Correo Electrónico"
									rules={[
										{
											type: 'email',
											message: 'El correo no es válido',
										},
										{
											required: true,
											message: 'Ingrese su email',
										}, {
											max: 50,
											message: "Máximo de 128 caracteres"
										}
									]}
								>
									<Input placeholder="example@mail.com" size="large" className="input-login" />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									name="telefono"
									label="Telefono"
									rules={[
										{
											required: true,
											message: 'Ingrese el telefono',
										}
									]}
								>
									<Input
										// type=''
										// html 
										size="large" />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									name="crear_cuenta"
									label="Crearme una cuenta"
									valuePropName='checked'
								>
									<Switch />
								</Form.Item>
							</Col>

							<Form.Item
								noStyle
								shouldUpdate={(prevValues, currentValues) => prevValues.crear_cuenta !== currentValues.crear_cuenta}
							>
								{({ getFieldValue }) =>
									getFieldValue('crear_cuenta') ? (
										<>
											<Col span={24}>
												<Form.Item
													label="Contraseña"
													name="password"
													hasFeedback
													rules={[
														{ required: true, message: 'Please, enter your password' },
														{
															max: 50,
															message: "Maximum 50 characters"
														}
													]}
												>
													<Input.Password placeholder="********" size="large" />
												</Form.Item>
											</Col>
											<Col span={24}>
												<Form.Item
													name="confirm"
													label="Password Confirm"
													dependencies={['password']}
													hasFeedback
													rules={[
														{
															required: true,
															message: 'Please, confirm your password',
														},
														{
															max: 50,
															message: "Maximum 50 characters"
														},
														({ getFieldValue }) => ({
															validator(_, value) {
																if (!value || getFieldValue('password') === value) {
																	return Promise.resolve();
																}
																return Promise.reject(new Error('Passwords do not match'));
															},
														}),
													]}
												>
													<Input.Password placeholder="********" size="large" />
												</Form.Item>
											</Col>
										</>
									) : null
								}
							</Form.Item>

							<Col span={24}>
								<Form.Item
									name='referal'
									label='¿Cómo nos encontró?'
									rules={[
										{ required: true, message: "Please tell us, how did you find about our site?" }
									]}
								>
									<Select
										size="large"
										options={[
											{
												value: 1,
												label: "Instagram"
											},
											{
												value: 2,
												label: "Facebook"
											},
											{
												value: 3,
												label: "Google"
											},
											{
												value: 4,
												label: "Influencer"
											},
											{
												value: 5,
												label: "Otro"
											}
										]}
									/>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Paragraph>Al registrarse, acepta los terminos y condiciones</Paragraph>
								{/* <Form.Item
									name="terminhos"
									valuePropName="checked"
								>
									<Checkbox
										onChange={({ target }) => this.setState({ privacy_checked: target.checked })}
									> I have read and agree to the </Checkbox>
									<Text
										style={{ color: "#207cff" }}
										className="hover"
										underline
										onClick={(e) => {
											e.stopPropagation()
											this.setState({ modal_visible_privacidad: true })
										}}
									>privacy policy</Text>
								</Form.Item> */}
							</Col>
						</Row>
						<Form.Item>
							<Form.Item
								noStyle
								shouldUpdate={(prevValues, currentValues) => prevValues.crear_cuenta !== currentValues.crear_cuenta}
							>
								{({ getFieldValue }) => {

									const is = getFieldValue('crear_cuenta')

									return <Button type="primary" htmlType="submit" block size="large"
										className={is ?? "whatsapp-button"}
										icon={
											!is && <WhatsAppOutlined />
										}
									>

										{is ? "Crear Cuenta" : "Solicitar Cotización y Contactar por Whatsapp"}
									</Button>
								}}
							</Form.Item>

						</Form.Item>
					</Form>
				</Spin>
			</>
		)
	}
}

export default function View(props) {

	let user = useContext(User)
	const setUser = useContext(SetUser)
	const setCarrito = useContext(SetCarrito)

	return <Register setCarrito={setCarrito} {...props} user={user} setUser={setUser} navigate={useNavigate()} />
}
import React, { Component, useState, createContext, useContext } from 'react';
import { Button, Upload, Row, Radio, Space, Col, Modal, Typography, Form, Input, InputNumber } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import {Carrito, SetCarrito } from '../../../Hooks/Carrito';


const axios = require("axios").default

const { Title, Paragraph } = Typography;

class ModalAgregarProducto extends Component {

    formRef = createContext();

    constructor(props) {
        super(props);
        this.state = {
            product: {},
            prioridad: 1
        }
    }

    componentDidMount = () => {
        this.loadForm();
    }

    onFormChange = (_, values) => {

        console.log(_, values);

    }

    loadForm = () => {
        const { productIdx, products } = this.props;
        if (productIdx === null) return;

        this.formRef.current?.setFieldsValue(products[productIdx])
    }

    onFormFinish = values => {
        const product = values;
        const id = this.props.productIdx;
        console.log(values, this.props.products);
        const arr = this.props.products;
        if (id !== null) {
            arr[id] = product;
        } else {
            arr.push(product);
        }

        this.props.setProducts(arr);
        this.props.onClose()
    }

    render() {
        return (
            <Form
                layout="vertical"
                onFinish={this.onFormFinish}
                onValuesChange={this.onFormChange}
                initialValues={{
                    prioridad: 1
                }}
                ref={this.formRef}
            >
                
                <Row justify={"center"} align={"middle"} gutter={[24, 24]}>
                    <Col xs={22} md={11}>
                        <Form.Item
                            className="agregar-producto-col-input"
                            label="Marca"
                            name="marca"
                        >
                            <Input placeholder="Ingresa la marca" maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col xs={22} md={11}>
                        <Form.Item
                            className="agregar-producto-col-input"
                            label="Modelo"
                            name="modelo"
                        >
                            <Input placeholder="Ingresa el modelo" />
                        </Form.Item>
                    </Col>
                    <Col xs={22}>
                        <Form.Item
                            className="agregar-producto-col-input"
                            label="Descripción"
                            name="descripcion"

                        >
                            <Input.TextArea row={5} maxLength={2000} />
                        </Form.Item>
                    </Col>
                    <Col xs={22} md={7}>
                        <Form.Item
                            className="agregar-producto-row-input"
                            label="Unidad de Medida"
                            name="um"

                        >
                            <Input placeholder="Litros, kilos, etc..." />
                        </Form.Item>
                    </Col>
                    <Col xs={22} md={7}>
                        <Form.Item
                            className="agregar-producto-row-input"
                            label="Cantidad"
                            name="cantidad"
                            rules={[
                                {
                                    required: true,
                                    message: "Ingresa la cantidad, por favor",
                                    pattern: new RegExp(/[0-9]+/)
                                }
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                    <Col  xs={22} md={8}>
                        <Form.Item
                            className="agregar-producto-row-input input-add-on"
                            label="Precio Unitario de Referencia"
                            name="precio_ref"
                        >
                            <InputNumber
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                className="width-100"
                                controls={false}
                                
                            />
                        </Form.Item>
                    </Col>
                    
                </Row>
                
                <Row justify="center">
                    <Col xs={{ span: 22 }} style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                        <Form.Item>
                            <Button
                                className="agregar-producto-button"
                                htmlType="submit"
                            >
                                Agregar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default function Vista(props) {

    let [image, setImage] = useState(null);
    const productos = useContext(Carrito)
    const setProductos = useContext(SetCarrito)

    return <Modal
        title={"Producto a Cotizar"}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        width={800}
        visible={props.visible}
        onCancel={() => props.onClose(image, props.productIdx)}
        className="agregar-producto"
    >
        
        <ModalAgregarProducto {...props} setImage={setImage} products={productos} setProducts={setProductos}/>
    </Modal>
}